.tab-menu {
  display: flex;
  justify-content: flex-end;
  .newly-tabs-button {
    
    button {
      //   position: relative;
      background-color: transparent;
      color: #ababab;
      border: none;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      min-width: 90px;
      padding: 10px;
      margin-right: 10px;
      // margin-bottom: 10px;
      cursor: pointer;
      border: 1px solid #fff;
      border-radius: 24px;
      text-transform: none;
    
    }
    button:hover {
      -ms-transform: none !important; /* IE 9 */
      -webkit-transform: none !important; /* Safari 3-8 */
      transform: none !important;
    }
    button.selected {
      color: #e94560 !important;
      font-weight: 600;
      background-color: #fff;
      border-radius: 24px;
    }
  }
}
.selected-button {
  background-color: #FFF;
  color: #E94560;
}
.vote-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.birthday-tab-menu {
  display: flex;
  .birthday-tabs-button {
    button {
      background-color: transparent;
      color: #ababab;
      border: none;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      padding: 10px;
      margin-right: 10px;
      cursor: pointer;
      border: 1px solid #fff;
      border-radius: 24px;
    }
    button:hover {
      -ms-transform: none !important; /* IE 9 */
      -webkit-transform: none !important; /* Safari 3-8 */
      transform: none !important;
    }
    button.selected {
      color: var(--selected-color) !important;
      font-weight: 600;
      background-color: var(--light-color);
      border-radius: 24px;
      border: 1px solid var(--selected-color);
    }
    button.correct {
      color: var(--light-color) !important;
      font-weight: 600;
      background-color: var(--green-color);
      border: 1px solid var(--green-color);
    }
  }
}

.bottom-right {
  width: 100%;
  max-width: max-content !important;
  padding-left: 10px;
  padding-right: 10px;
  background: #16213e;
  opacity: 0.8;
  border-radius: 10px;
  position: absolute;
  bottom: 5px;
  right: 5px;
  padding: 2px;
  justify-content: center;
  align-items: center;
}

.top-right {
  width: 100%;
  max-width: 58px !important;
  background: #16213e;
  opacity: 0.8;
  border-radius: 10px;
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 2px;
  justify-content: center;
  align-items: center;
}
.seen-container {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 100px;
  height: 32px;
  background-color: #ffffff;
  border-radius: 50px;
  .MuiFormControl-root label,
  svg.MuiSvgIcon-root {
    color: #e94560 !important;
  }
}

hr.solid {
  border-top: 0.5px solid #979797;
}

.bx-bookmark {
  position: absolute;
  padding: 3px;
  right: 4px;
  top: 4px;
  width: 32px;
  height: 32px;
  background-color: var(--secondary-color);
  border-radius: 50%;
}
.inCinema {
  background-color: var(--dark-color);
  border-radius: 0px 0px 4px 4px;
  margin-top: -5px !important;
}
.release-avatar {
  width: 100%;
  max-width: 25px;
  height: 25px !important;
  margin-left: -7px !important;
  &:last-child {
    // border: 0;
  }
}

.news-carousel-container {
  .carousel-root {
    li.dot {
      height: 9.5px;
      width: 9.5px;
      background: #d9d9d9;
    }
    li.dot.selected {
      background: var(--selected-color);
      width: 100%;
      max-width: 57px;
      border-radius: 100px;
      height: 9.5px;
    }
  }
  .carousel.carousel-slider {
    padding-bottom: 40px;
  }
}

.blogs-carousel-container {
  .carousel-root {
    li.dot {
      height: 9.5px;
      width: 9.5px;
      background: #d9d9d9;
    }
    li.dot.selected {
      background: var(--selected-color);
      width: 100%;
      max-width: 57px;
      border-radius: 100px;
      height: 9.5px;
    }
  }
  .carousel.carousel-slider {
    padding-bottom: 40px;
  }
}

#connectWithYourCinematch {
  .swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 400px;
    max-width: 100%;
    position: relative;
    div.user-details {
      position: absolute;
      bottom: -2px;
      left: 0;
      right: 0;
      width: 100%;
      padding: 15px;
      background: linear-gradient(
          180deg,
          rgba(233, 69, 96, 0.2) 26.28%,
          rgba(212, 63, 87, 0.181935) 68.22%,
          rgba(0, 0, 0, 0) 80.73%,
          rgba(4, 0, 1, 0.152) 95.77%
        ),
        #16213e;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 16px 16px 0px 0px;
    }
  }

  .swiper-slide img.dp {
    display: block;
    // width: 100%;
    height: 100%;
    border-radius: 16px 16px 0px 0px;
  }
}
