.long-nudge{
    width: 100%;
    max-width: 790px;
    border-radius: 10px;
    padding-left: 16px;
}

.nudge-subtext {
  font-weight: 500px;
  color: #ffff;
  opacity: 0.5;
  margin-top: 0px;
}

.nudge-heading {
    color: #ffff;
    margin: 0px;
}