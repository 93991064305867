@import "../../media-queries.scss";

.loader {
  position: fixed;
  top: 0;
  // left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100%;
  z-index: 999999;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-button {
  background: #ffffff !important;
  border-radius: 40px !important;
  height: 40px ;
  text-transform: none !important;
  color: var(--selected-color) !important;
}

.custom-button.selected-btn {
  background: var(--selected-color) !important;
  color: #ffffff !important;
}

.tab-menu {
  display: flex;
  justify-content: flex-end;
  .newly-tabs-button {
    a,
    button {
      //   position: relative;
      background-color: transparent;
      color: #ababab;
      border: none;
      font-weight: 500 !important;
      min-width: 90px;
      padding: 10px;
      margin-right: 10px;
      cursor: pointer;
      border: 1px solid #fff;
      border-radius: 24px;
      max-height: 40px;
      display: inline-block;
      text-align: center;
      @include xs {
        max-height: 30px !important;
        min-width: 50px !important;
      }
    }
    a:hover,
    button:hover {
      color: var(--selected-color) !important;
      font-weight: 600 !important;
      background-color: #fff;
    }
    a.selected,
    a.active,
    button.selected,
    button.active {
      color: var(--selected-color) !important;
      font-weight: 600 !important;
      background-color: #fff;
    }
  }
}


  .newly-tabs-button-review {
    a,
    button {
      //   position: relative;
      background-color: transparent;
      color: #ababab;
      border: none;
      font-weight: 500 !important;
      min-width: 90px;
      padding: 10px;
      margin-right: 10px;
      cursor: pointer;
      border: 1px solid #fff;
      border-radius: 24px;
      max-height: 40px;
      display: inline-block;
      text-transform: lowercase !important;
      text-align: center;
      @include xs {
        max-height: 30px !important;
        min-width: 50px !important;
      }
    }}
  
.selected-button {
  background: #ffffff !important;
  border-radius: 40px !important;
  height: 40px ;
  font-weight: 600 !important;
  text-transform: lowercase !important;
  color: var(--selected-color) !important;
}

.footerbg {
  background: rgba(34, 36, 42, 1);
}
.season-view-box {
  width: 100%;
  max-width: 320px;
  background: var(--secondary-color);
  border-radius: 100px;
  color: var(--text-light);
  .default-view {
    border: none;
    background: transparent;
    height: 44px;
    color: var(--text-light);
    @include xs {
      max-height: 30px !important;
      min-width: 50px !important;
    }
  }
  .year {
    color: #94a0bf;
  }
}

.hidden-view-container {
  position: absolute;
  margin-top: 10px;
  width: 100%;
  z-index: 9;
  // max-width: 320px;
  background: var(--secondary-color);
  border-radius: 10px;
  .hidden-view {
    background: transparent;
    height: 44px;
    @include xs {
      max-height: 30px !important;
      min-width: 50px !important;
    }
    svg {
      opacity: 0;
    }
  }
}

.rating-slider-container {
  .MuiSlider-rail {
    height: 15px !important;
    border-radius: 50px !important;
    opacity: 1;
  }
  .MuiSlider-mark {
    height: 15px !important;
    opacity: 0;
  }
  .MuiSlider-track {
    background-color: var(--green-color) !important;
    height: 15px !important;
    border: none;
  }

  .MuiSlider-thumb::before {
    background-color: var(--selected-color);
    height: 28px;
    width: 28px;
  }
}

.icon-container {
  border-radius: 50%;
  background-color: var(--secondary-color);
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MuiSlider-valueLabel {
  font-size: 20px !important;
  font-weight: bold !important;
}

.trailer-button-box {
  position: absolute;
  width: auto !important;
  text-align: center;
  top: 50%;
  transform: translateY(-60%);
  .trailer-button {
    background-color: rgba(255, 255, 255, 0.8);
    width: 98px;
    height: 28px;
    border-radius: 100px;
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.6);
    // text-transform: none;
    svg {
      color: var(--secondary-color) !important;
    }
  }
}

.movie-card {
  background-size: 100% 100%;
}

* {
  box-sizing: border-box;
}

.d-none {
  display: none !important;
}

.relative-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}