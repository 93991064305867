@import "../../media-queries.scss";

.imagewidth {
  max-width: 100%;
  height: auto;
  overflow: hidden;
}

.imagewidth img {
  width: 100%;
  height: auto;
}

// flaoting buttons

.sticky-container {
  padding: 0px;
  margin: 0px;
  position: fixed;
  right: -130px;
  bottom: 100px;
  width: 235px;
  z-index: 1100;
  @include xs {
    width: 202px;
  }
  @include sm {
    width: 211px;
  }
}
.sticky li {
  list-style-type: none;
  background-color: #fff;
  color: #efefef;
  height: 64px;
  padding: 0px;
  margin: 0px 0px 1px 0px;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  cursor: pointer;

  @include xs {
    height: 32px;
  }

  @include sm {
    height: 40px;
  }

  button {
    display: flex;
    align-items: center;
    p {
      color: var(--selected-color);
    }
  }
}
.sticky li:hover {
  margin-left: -115px;
}
.sticky li svg {
  float: left;
  margin-right: 20px;
  @include xs {
    height: 32px;
    width: 32px;
  }
  @include sm {
    height: 40px;
    width: 40px;
  }
}
.sticky li p {
  margin: 0px;
}
.sticky li p a {
  text-decoration: none;
  color: #2c3539;
}
.sticky li p a:hover {
  text-decoration: underline;
}
