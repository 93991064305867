@import "../../media-queries.scss";

.otp-container {
  justify-content: center;
  margin-right: -20px;
  input {
    background-color: transparent !important;
    max-width: 40px;
    width: 100%;
    border: none;
    border-bottom: 2px solid var(--muted-color);
    color: var(--selected-color);
    font-size: 24px;
    @include xs {
      font-size: 10px;
      margin-right: 5px !important;
    }
    @include sm {
      font-size: 16px;
      margin-right: 7px !important;
    }
    @include md {
      font-size: 20px;
      margin-right: 10px !important;
    }
    @include lg {
      font-size: 24px;
      margin-right: 20px !important;
    }
    @include xl {
      font-size: 28px;
      margin-right: 24px !important;
    }
  }
}

fieldset.MuiOutlinedInput-notchedOutline {
  border-color: aliceblue !important;
}

.country-code-select {
  position: relative;
  .MuiFormLabel-root {
    display: none;
  }
  label.MuiInputLabel-shrink {
    display: inline-block;
  }
}

.country-selected {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
  z-index: 1;
  cursor: pointer;
  display: inline-block;
  .flag {
    padding-right: 10px;
    font-size: 24px;
    border-right: 1px solid #fff;
    @media only screen and (max-width: 420px) {
      display: none;
    }
  }
  .code {
    align-self: center;
    padding-left: 10px;
  }
}

#recaptchaBox {
  position: absolute;
  right: 0;
  bottom: 50px;
  z-index: 99;
}

.google-btn {
  text-align: center;
  justify-content: center;
  position: relative;
  display: flex;
  align-items: center;

  img {
    position: absolute;
    left: 2px;
    height: 41px;
    width: 41px;
    background-color: #fff !important;
    border-radius: 50%;
    padding: 1px;

    @include xs {
      height: 31px;
      width: 31px;
      left: 1px;
    }
  }
}

.google-btn:hover {
  span {
    color: #fff !important;
  }
}
