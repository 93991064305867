.quiz-point-container {
  //   background-color: red;
  height: 40px;
  align-items: center;
}

.quiz-point-container.over-image {
  position: absolute;
  bottom: -2px;
  width: 100%;
}

.coin-container {
  width: 100%;
  max-width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
}
.point-container {
  border: 2px solid #d9d9d9;
}

.user-profile-img-container {
  position: absolute;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;

  .user-profile-img {
    border: 5px solid var(--gun-powder-color);
    border-radius: 50%;
  }

  .edit-icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 99;
    cursor: pointer;
  }
}

.profile-more-icon {
  position: absolute;
  right: 5px;
  top: 10px;
  z-index: 999;
}

.MuiDatePickerToolbar-root {
  span.MuiTypography-root {
    display: none;
  }
}

.MuiCalendarOrClockPicker-root {
  span.MuiTypography-root {
    color: #fff !important;
  }

  .MuiButtonBase-root:hover,
  .PrivatePickersYear-root button:hover {
    color: #fff !important;
    background-color: var(--selected-color) !important;
  }

  .MuiButtonBase-root.Mui-selected,
  .PrivatePickersYear-root button.Mui-selected {
    color: #fff !important;
    background-color: var(--selected-color) !important;
  }
}
