@import "./media-queries.scss";

html,
body,
main,
#frenzi-root {
  max-width: 100vw;
}

main::-webkit-scrollbar,
body::-webkit-scrollbar,
#frenzi-root::-webkit-scrollbar {
  display: none;
}

.scroll-to-icon {
  position: fixed;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  bottom: 30px;
  right: 30px;
  z-index: 999;
  opacity: 0.3;
  max-width: 50px;
  max-height: 50px;

  @include xs {
    max-height: 25px !important;
    max-width: 25px !important;
    bottom: 15px;
    right: 15px;
  }
}

.scroll-to-icon:hover {
  opacity: 1;
}

.rounded-btn {
  @include xs {
    max-height: 32px !important;
  }
}

.rounded-btn.small {
  height: 32px !important;
  width: auto !important;
  font-weight: 400;
}

.Toastify {
  .my-frenzi-toast {
    z-index: 999999;
  }
}

select:focus-visible,
div:focus-visible,
input:focus-visible,
textarea:focus-visible {
  outline: none !important;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis-2 {
  white-space: normal;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

main.fixed-full-view::-webkit-scrollbar {
  display: none;
}

.quiz-select-btn-grp {
  overflow-x: auto;
  scrollbar-width: thin;
}

.quiz-select-btn-grp::-webkit-scrollbar {
  height: 7px;
}

.quiz-select-btn-grp::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 1) !important;
  border-radius: 7px !important;
}

.quiz-select-btn-grp::-webkit-scrollbar-thumb {
  background-color: darkgrey !important;
  border-radius: 7px !important;
  height: 5px;
  width: 5px;
}

.lowercase {
  text-transform: lowercase !important;
}

main.fixed-full-view::-webkit-scrollbar,
#frenzi-root::-webkit-scrollbar {
  display: none;
}

.overflow-no-scroll {
  overflow-x: hidden;
  overflow-y: hidden;
  // margin-bottom: 20px !important;

  @include sm {
    overflow-x: auto;
  }

  @include xs {
    overflow-x: auto;
  }
}

.overflow-no-scroll:hover {
  overflow-x: auto;
}

.overflow-no-scroll::-webkit-scrollbar {
  display: block;

  @include sm {
    display: none;
  }

  @include xs {
    display: none;
  }
}

.overflow-no-scroll::-webkit-scrollbar {
  width: 7px !important;
  height: 7px;
}

.overflow-no-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 1) !important;
  border-radius: 7px !important;
}

.overflow-no-scroll::-webkit-scrollbar-thumb {
  background-color: var(--light-color) !important;
  border-radius: 7px !important;
  height: 5px;
  width: 5px;
}

// div::-webkit-scrollbar-track {
//   box-shadow: inset 0 0 6px rgba(0, 0, 0, 1) !important;
//   border-radius: 7px !important;
// }

// div::-webkit-scrollbar-thumb {
//   background-color: var(--selected-color) !important;
//   border-radius: 7px !important;
//   // height: 5px;
//   width: 5px;
// }

/* Scroll bar stylings */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--secondary-color);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 1) !important;
  border-radius: 7px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--selected-color);
  cursor: pointer;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--selected-color);
}

// .add-platform-container,
// .add-language-container {
//   cursor: pointer;
//   svg {
//     transition: 0.3s;
//     transform: rotate(0deg);
//   }
// }

// .add-platform-container:hover,
// .add-language-container:hover {
//   svg {
//     transform: rotate(90deg);
//   }
// }

svg.animate-close {
  transition: 0.3s;
  transform: rotate(0deg);
}

svg.animate-close:hover {
  transform: rotate(90deg);
}

.react-multi-carousel-list ul li {
  transition: all 0.2s ease-in-out;
}

.react-multi-carousel-list ul li:hover {
  transform: scale(1.1);

  @include xs {
    transform: scale(1) !important;
  }

  @include sm {
    transform: scale(1) !important;
  }
}

.react-multi-carousel-list.overflow-hidden {
  overflow: hidden !important;
}

.react-multi-carousel-list.no-scale ul li:hover {
  transform: none !important;
}

.no-scale ul li:hover {
  transform: none !important;
}

button.custom-button,
button.bg-light {
  transition: 0.5s;
}

button.custom-button:hover,
button.bg-light:hover {
  background-color: var(--selected-color) !important;
  color: #fff !important;

  svg {
    color: #fff !important;
  }

  .span {
    color: #fff !important;
  }
}

button.bg-selected {
  transition: 0.5s;
}

button.bg-selected:hover {
  color: var(--selected-color) !important;
  background-color: #fff !important;

  svg {
    color: var(--selected-color) !important;
  }

  span,
  span.text-light {
    color: var(--selected-color) !important;
  }

  svg.invert {
    filter: invert(36%) sepia(18%) saturate(5930%) hue-rotate(323deg)
      brightness(97%) contrast(87%);
    transition: 0.5s;
  }
}

button.bg-selected.Mui-disabled {
  opacity: 0.8;
  cursor: not-allowed !important;
}

button.bg-light.Mui-disabled {
  opacity: 0.8;
  cursor: not-allowed !important;
}

.MuiPaper-root.fixed-navbar-top {
  border-radius: 0px;
}

.fixed-navbar-top {
  height: 100px;
  width: 100%;
  z-index: 1007;
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  background: linear-gradient(
    180deg,
    rgba(26, 26, 46, 0.9) 20%,
    rgba(0, 0, 0, 0) 100%
  ) !important;
  @include xs {
    height: 55px;
    // background: none !important;
  }
  @include sm {
    height: 55px;
    background: none !important;
  }
}

.fixed-navbar-top-mob {
  height: 100px;
  width: 100%;
  z-index: 1007;
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  // background: linear-gradient(
  //   180deg,
  //   rgba(0, 0, 0, 0.9) 20%,
  //   rgba(0, 0, 0, 0) 100%
  // ) !important;
  @include xs {
    height: 55px;
    background: none !important;
  }
  @include sm {
    height: 55px;
    background: none !important;
  }
}

.hover-link:hover {
  color: var(--selected-color);
  cursor: pointer;
}

.fixed-navbar {
  position: fixed;
  z-index: 1010;
  top: 0;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 100vh;
  border-radius: 0px !important;
  background-color: rgba(26, 26, 46, 0.8) !important;

  > div a {
    margin-top: 30px;
    display: block;
  }

  .right-search-btn-focused {
    svg.search-icon {
      color: var(--selected-color) !important;
    }
  }
  .right-search-btn-unfocused {
    svg.search-icon {
      color: var(--text-light-grey-color) !important;
    }
  }

  .menu-container::-webkit-scrollbar {
    display: none !important;
  }
}

.fixed-navbar a div.text-center,
.fixed-navbar div.small-menu a {
  img.hover-icon {
    display: none;
  }
}

.fixed-navbar a div.text-center:hover,
.fixed-navbar div.small-menu a:hover {
  > img:first-child {
    display: none;
    transition: all 0.4s ease-in-out;
    // transform: scale(1.3);
  }

  img.hover-icon {
    display: inline-block;
    transition: all 0.4s ease-in-out;
    // transform: scale(1.1);
  }

  // .list-style {
  //   font-weight: 700;
  //   color: var(--selected-color);
  // }
}

.profile-nav-menu li.profile-menu-item:hover {
  color: var(--selected-color) !important;

  p {
    color: var(--selected-color) !important;
  }

  div svg {
    color: var(--selected-color) !important;
  }
}

.demo-simple-select-point fieldset {
  border: none !important;
}

.positive-point-summary {
  border-radius: 50%;
  transform: rotate(180deg);
  padding: 2px;
  background-color: var(--green-color);
  margin-left: 10px;
}

.negative-point-summary {
  border-radius: 50%;
  padding: 2px;
  background-color: var(--selected-color);
  margin-left: 10px;
}

.rounded-border-btn {
  @include xs {
    max-height: 32px !important;
  }
}

.rounded-border-btn {
  border-radius: 40px !important;
  height: 44px !important;
  width: auto;
  border: 1px solid #fff !important;
  color: #fff !important;
  text-transform: none !important;
  font-weight: 500 !important;
  position: relative;

  .MuiAvatar-root {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);

    img {
      max-width: 24px;
      max-height: 24px;
    }
  }

  @include xs {
    height: 24px;
    border-radius: 20px !important;
    max-width: 75%;
  }

  @include sm {
    height: 30px;
    border-radius: 26px !important;
    max-width: 80%;
  }

  @include md {
    height: 36px;
    border-radius: 32px !important;
    max-width: 90%;
  }

  @include lg {
    height: 44px;
    border-radius: 40px !important;
    max-width: 100%;
  }

  @include xl {
    height: 50px;
    border-radius: 46px !important;
    max-width: 100%;
  }
}

.rounded-border-btn:hover,
.rounded-border-btn.active {
  border-color: var(--selected-color) !important;
  color: var(--selected-color) !important;
}

.scale,
.scale-1 {
  transition: all 0.2s ease-in-out;
}

.scale:hover {
  transform: scale(1.2);

  @include xs {
    transform: scale(1) !important;
  }

  @include sm {
    transform: scale(1) !important;
  }
}

.scale-1:hover {
  transform: scale(1.1);

  @include xs {
    transform: scale(1) !important;
  }

  @include sm {
    transform: scale(1) !important;
  }
}

.poll-bar {
  width: 100%;
  background-color: #000;
  border-radius: 50px;
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  overflow: hidden;

  .detail {
    position: relative;
    z-index: 99;
  }

  .first-overlay {
    position: absolute;
    background-color: var(--selected-color);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    z-index: 9;
  }
}

.react-multi-carousel-list {
  overflow: visible !important;
  // @include xs {
  //   overflow: auto !important;
  // }
  // @include sm {
  //   overflow: auto !important;
  // }
}

.MuiModal-root {
  .react-multi-carousel-list {
    overflow: hidden !important;
    padding-top: 20px;
  }
}

.hover-img-icon {
  transition: all 0.2s ease-in-out;
  border: 1px solid grey;

  > img:first-child {
    transition: all 0.2s ease-in-out;
  }

  > img:last-child {
    display: none;
    transition: all 0.2s ease-in-out;
  }
}

.hover-img-icon:hover {
  background-color: var(--selected-color);
  border: 1px solid var(--selected-color);

  > img:first-child {
    display: none;
  }

  > img:last-child {
    display: block;
  }
}

.hover-red:hover {
  color: var(--selected-color) !important;
}

.Toastify__toast-theme--dark {
  background-color: var(--secondary-color) !important;
  color: var(--selected-color) !important;
  border-radius: 32px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.Toastify__close-button {
  top: 50%;
  position: absolute;
  right: 20px;
  transform: translateY(-50%);
}

.swiper-button-next:after,
.swiper-button-prev:after {
  // color: var(--selected-color) !important;
  color: var(--light-color) !important;
  font-size: 20px !important;
}

.swiper-button-next,
.swiper-button-prev {
  background: rgba(0, 0, 0, 0.5);
  height: 43px !important;
  width: 43px !important;
  border-radius: 50%;

  @include xs {
    display: none !important;
  }

  @include sm {
    display: none !important;
  }
}

.copy-share-icon {
  width: 32px !important;
  height: 32px !important;
  border-radius: 50%;
  padding: 7px;
  background-color: var(--selected-color);
}

svg.notification-icon:hover {
  color: var(--selected-color) !important;
}

span.my-badge {
  background-color: var(--selected-color) !important;
  padding: 3px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 15px;
}

.overlay-open::after {
  content: "";
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: 0;
}

// listing clips

.list-clip-player {
  video {
    height: 100% !important;
    border-radius: 10px;
    width: 100% !important;
    object-fit: cover;

    @include xs {
      border-radius: 0px !important;
      height: 400px !important;
    }
  }

  .mute-unmute-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 5px;
    z-index: 10;
    border-radius: 50%;
    // opacity: 0;
  }

  .delete-icon {
    position: absolute;
    left: 10px;
    top: 10px;
    padding: 2px;
    z-index: 10;
    border-radius: 50%;
  }

  .clips-action-icon {
    position: absolute;
    z-index: 9;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // opacity: 1;
  }

  .like-action-box {
    p {
      margin-left: 4px !important;
    }
  }
}

ins.adsbygoogle[data-ad-status="unfilled"] {
  display: none !important;
  // background-color: #e94560 !important;
}

.grecaptcha-badge {
  visibility: hidden;
}


// .new-blog-layout .faq-accordian {
//   div,
//   p {
//     color: #fff !important;
//   }

//   > div.MuiAccordion-root {
//     border: 0px !important;
//   }
// }

// .faq-accordian {
//   div,
//   p {
//     color: #fff !important;
//   }
// }

.faq-container-blog {
  background-color: #ffffff;
  // border: 1px solid black;
  border-radius: 18px;
}
.overflow-hidden {
  overflow: hidden;
}

.dark-bg-glass {
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
}

.paginationSwiper {
  .swiper-pagination {
    position: relative;
    bottom: 0px;
  }

  .swiper-pagination-bullet {
    background-color: rgba(217, 217, 217, 1);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }

  .swiper-pagination-bullet-active {
    background-color: #e94560;
    width: 60px;
    height: 10px;
    border-radius: 100px;
  }
}

p.shadow,
span.shadow {
  text-shadow: 1px 1px 2px #000;
}

.bold-shadow-link {
  text-shadow: 1px 1px 2px #000;
  font-weight: 700 !important;
}

.searchbar {
  background-color: #ffffff !important;
}

.login-box {
  .MuiPaper-elevation {
    box-shadow: none !important;
  }
}

.MuiSkeleton-root {
  background: #2a3745 !important;
  border-radius: 3px;
}

.MuiSkeleton-root::after {
  background: linear-gradient(90deg, transparent, #ddd, transparent) !important;
}

.unsubscribe-form {
  .MuiRadio-root.Mui-checked > span > svg.MuiSvgIcon-root {
    color: var(--selected-color) !important;
  }
}

.google-btn {
  @include xs {
    width: 100% !important;
  }
}

.next-slide {
  border-radius: 7px;
  width: 100%;
  height: calc(100% - 20px);
  background-color: #3f3d56;

  .ske {
    height: 100%;
    width: 100%;
  }
}
.minw {
  min-width: 100px !important;
}

.next-slide.large-nxt {
  min-width: 232px;
  min-height: 360px;
  @include xs {
    min-width: 100px;
    min-height: 150px;
  }
  @include sm {
    min-width: 214px;
    min-height: 360px;
  }
  @include md {
    min-width: 205px;
    min-height: 360px;
  }
}

.next-slide.small-nxt span {
  min-width: 140px;
  min-height: 210px;
  @include xs {
    min-width: 100px;
    min-height: 150px;
  }
  @include sm {
    min-width: 140px;
    min-height: 210px;
  }
}

.swiper-button-disabled {
  display: none !important;
}

.align-miidle-wrapper {
  .swiper-wrapper {
    align-items: center !important;
  }
}

.sticky-ads {
  .desktopx {
    position: sticky !important;
    top: 100px;
  }
}

.desktopx {
  width: 100%;
  text-align: center;
}

.mobilex ins {
  max-width: 100%;
  margin: 0 !important;
}

.line-loader {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999999;
  .MuiLinearProgress-root {
    background-color: transparent !important;
    .MuiLinearProgress-bar {
      background-color: var(--selected-color) !important;
    }
  }
}

.watchlist-input {
  background-color: transparent;
  border-radius: 4px;
  padding: 16.5px 14px;
  border: 1px solid #fff;
}

// .new-blog-layout {
//   background-color: #fff !important;
//   p,
//   a,
//   h1,
//   h2,
//   h3,
//   h4,
//   h5,
//   h6,
//   div,
//   span,
//   input,
//   label,
//   li,
//   textarea {
//     color: #000;
//   }

//   .text-selected {
//     color: var(--selected-color) !important;
//   }
// }

.name-initials{
  font-size: 40px;
  background-color: var(--selected-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}

#newUserCard {
  .swiper-slide {
    width: max-content;
    min-width: 140px;

    a {
      display: inline-block;
      max-height: 68px;
      max-width: 68px;
      background: linear-gradient(to right, #ff6699, #5c3da4);
      padding: 2.5px;
      border-radius: 50%;
      img {
        border: 4px solid #fff;
      }
    }
  }
}

#trailerCard {
  .swiper-slide {
    width: max-content;
    min-width: 250px;
  }
}

.top-ten-txt {
  width: 37px;
  height: 65px;
  font-style: normal;
  font-weight: 700;
  line-height: 65px;
  text-transform: capitalize;
  color: #ffffff;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: var(--selected-color);
  position: absolute;
  bottom: 10px;
  left: 10px;
}
.unread-count {
  position: absolute;
  top: -2px;
  right: 12px;
  background-color: red;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  font-size: 12px;
  text-align: center;
  align-items: center;
  span{
    display: inline-block;
    width: 100%;
  }
}
