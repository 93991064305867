@import "./media-queries.scss";

body {
  --primary-color: #1a1a2e;
  --background-color: #1a1a2e;
  --secondary-color: #16213e;
  --text-light-grey-color: #b7b6b9;
  --selected-color: #e94560;
  --light-color: #fff;
  --green-color: #1daa10;
  --dark-color: #000;
  --muted-color: #b7b6b9;
  --text-dark-grey-color: #979797;
  --grey-color: #2f2e41;
  --violet-color: #474463;
  --light-violet-color: #938f99;
  --gun-powder-color: #3f3d56;
  --pink-color: #ffc1c8;
  --selected-dark-color: #c0334a;
  --light-white-color: #d9d9d9;
  --footer-color: #22242a;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  // font-family: "Gill Sans Nova" !important;
  font-family: "Inter", sans-serif;
  color: var(--light-color);
  // font-family: "Poppins", sans-serif;
}

a,button,p,h1,h2,h3,h4,h5,h6,span,input,textarea,div{
  font-family: "Inter", sans-serif !important;
  color: var(--light-color);
}

.text-light-grey {
  color: var(--text-light-grey-color) !important;
}

.text-light-white {
  color: var(--light-white-color) !important;
}

svg.MuiSvgIcon-root.text-light-grey {
  color: var(--text-light-grey-color) !important;
}

.text-secondary {
  color: var(--secondary-color) !important;
}

div svg.text-muted {
  color: var(--muted-color) !important;
}

.text-muted {
  color: var(--muted-color) !important;
  p,
  span {
    color: var(--muted-color) !important;
  }

  .progress-container span.text-light {
    color: var(--light-color) !important;
  }
}

.text-green {
  color: var(--green-color) !important;
}

.text-selected {
  color: var(--selected-color) !important;
}

.text-pink {
  color: var(--pink-color) !important;
}

button svg.text-selected {
  color: var(--selected-color) !important;
}

div svg.text-selected {
  color: var(--selected-color) !important;
}

.text-light {
  color: var(--light-color) !important;
  textarea {
    color: var(--light-color) !important;
  }
}

.text-dark,
svg.text-dark,
svg.MuiSvgIcon-root.text-dark {
  color: var(--dark-color) !important;
}
.text-dark-grey {
  color: var(--text-dark-grey-color) !important;
}
.text-violet {
  color: var(--light-violet-color) !important;
}

.bg-primary {
  background-color: var(--primary-color) !important;
}
.bg-light {
  background-color: var(--light-color) !important;
}

div.bg-light {
  background-color: var(--light-color) !important;
}

.bg-selected {
  background-color: var(--selected-color) !important;
}

.bg-secondary {
  background-color: var(--secondary-color) !important;
}

.bg-muted {
  background-color: var(--muted-color) !important;
}
.bg-grey {
  background-color: var(--grey-color) !important;
}
.bg-violet {
  background-color: var(--violet-color) !important;
}
.bg-gunpowder {
  background-color: var(--gun-powder-color) !important;
}
.bg-pink {
  background-color: var(--pink-color) !important;
}

.bg-green {
  background-color: #1daa10;
}

.relative {
  position: relative !important;
}
.absolute {
  position: absolute !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}

// dynamic and responsive padding and margin :-

$sides: (top, bottom, left, right);

@for $i from 1 through 300 {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$i} {
      @include xs {
        margin-#{$side}: #{$i/3.5}px !important;
      }
      @include sm {
        margin-#{$side}: #{$i/2.5}px !important;
      }
      @include md {
        margin-#{$side}: #{$i/1.5}px !important;
      }
      @include lg {
        margin-#{$side}: #{$i}px !important;
      }
      @include xl {
        margin-#{$side}: #{$i * 1.5}px !important;
      }
    }
    .p#{str-slice($side, 0, 1)}-#{$i} {
      @include xs {
        padding-#{$side}: #{$i/4.5}px !important;
      }
      @include sm {
        padding-#{$side}: #{$i/2.5}px !important;
      }
      @include md {
        padding-#{$side}: #{$i/1.5}px !important;
      }
      @include lg {
        padding-#{$side}: #{$i}px !important;
      }
      @include xl {
        padding-#{$side}: #{$i * 1.5}px !important;
      }
    }
  }
  .m-#{$i} {
    @include xs {
      margin: #{$i/3}px !important;
    }
    @include sm {
      margin: #{$i/2}px !important;
    }
    @include md {
      margin: #{$i/1.5}px !important;
    }
    @include lg {
      margin: #{$i}px !important;
    }
    @include xl {
      margin: #{$i * 1.5}px !important;
    }
  }
  .p-#{$i} {
    @include xs {
      padding: #{$i/3}px !important;
    }
    @include sm {
      padding: #{$i/2}px !important;
    }
    @include md {
      padding: #{$i/1.5}px !important;
    }
    @include lg {
      padding: #{$i}px !important;
    }
    @include xl {
      padding: #{$i * 1.5}px !important;
    }
  }
}

@for $i from 1 through 999 {
  .mw-#{$i} {
    max-width: #{$i}px !important;
  }
  .mh-#{$i} {
    max-height: #{$i}px !important;
  }
}

.mhr-60 {
  max-height: 60vh !important;
}

.fw-300 {
  font-weight: 300 !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.fw-800 {
  font-weight: 800 !important;
}
.fw-900 {
  font-weight: 900 !important;
}

.vh-100 {
  height: 100vh !important;
}
.wv-100 {
  width: 100vw !important;
}

.h-100 {
  height: 100% !important;
}

.h-50 {
  height: 50% !important;
}

.h-25 {
  height: 25% !important;
}

.h-75 {
  height: 75% !important;
}

.h-80 {
  height: 80% !important;
}

.h-90 {
  height: 90% !important;
}

.w-75 {
  width: 75% !important;
}

.w-50 {
  width: 50% !important;
}

.w-40 {
  width: 40% !important;
}

.w-100 {
  width: 100% !important;
}

.w-90 {
  width: 90% !important;
}

.w-80 {
  width: 80% !important;
}

@for $i from 1 through 90 {
  .fs-#{$i} {
    @include xs {
      font-size: #{$i/1.4}px !important;
    }
    @include sm {
      font-size: #{$i/1.3}px !important;
    }
    @include md {
      font-size: #{$i/1.2}px !important;
    }
    @include lg {
      font-size: #{$i}px !important;
    }
    @include xl {
      font-size: #{$i * 1.2}px !important;
    }
  }
  .lh-#{$i} {
    @include xs {
      line-height: #{$i/1.5}px !important;
    }
    @include sm {
      line-height: #{$i/1.3}px !important;
    }
    @include md {
      line-height: #{$i/1.1}px !important;
    }
    @include lg {
      line-height: #{$i}px !important;
    }
    @include xl {
      line-height: #{$i * 1.2}px !important;
    }
  }
  .ls-#{$i} {
    letter-spacing: #{$i/10}px !important;
  }
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.t-underline {
  text-decoration: underline;
}
input:focus-visible {
  outline: none !important;
}

.rounded-btn {
  border-radius: 40px !important;
  height: 44px !important;
  width: 100%;
  border: 0;
  text-transform: none !important;
  font-weight: 600 !important;
  position: relative;
  .MuiAvatar-root {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    img {
      max-width: 24px;
      max-height: 24px;
    }
  }
  @include xs {
    height: 24px;
    border-radius: 20px !important;
    width: 75%;
  }
  @include sm {
    height: 30px;
    border-radius: 26px !important;
    width: 80%;
  }
  @include md {
    height: 36px;
    border-radius: 32px !important;
    width: 90%;
  }
  @include lg {
    height: 44px;
    border-radius: 40px !important;
    width: 100%;
  }
  @include xl {
    height: 50px;
    border-radius: 46px !important;
    width: 100%;
  }
}

.icon-btn {
  border-radius: 7px !important;
  height: 44px !important;
  width: 100%;
  border: 0;
  text-transform: none !important;
  font-weight: 600 !important;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .MuiAvatar-root {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    img {
      max-width: 24px;
      max-height: 24px;
    }
  }
  @include xs {
    height: 26px !important;
  }
  @include sm {
    height: 30px !important;
  }
  @include md {
    height: 36px !important;
  }
  @include lg {
    height: 44px !important;
  }
  @include xl {
    height: 50px !important;
  }
}

.MuiFormControl-root label,
svg.MuiSvgIcon-root,
.MuiFormControl-root input {
  color: var(--light-color) !important;
}

.pointer {
  cursor: pointer;
}

.pointer-disabled {
  cursor: not-allowed !important;
}

a,
a button {
  text-decoration: none !important;
}

.MuiPaper-root:not(.fixed-navbar, .searchbar, .fixed-navbar-top) {
  background-color: var(--background-color) !important;
}
// DISPLAY

.relative {
  position: relative;
}
.absolute {
  position: absolute !important;
}

.top-centered {
  top: 50%;
  transform: translateY(-50%);
}

.my-badge {
  width: 100%;
  background: #16213e;
  margin-top: 3px;
  opacity: 0.8;
  border-radius: 10px;
  padding: 2px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.ask-friends {
  background: #fff;
  border-radius: 10px;
  padding: 2px;
  padding-bottom: 3px;
  padding-right: 10px;
  padding-left: 10px;
  display: inline-block;
}

.left-centered {
  left: 50%;
  transform: translateX(-50%);
}

.f-1 {
  flex: 1;
}

.d-flex {
  display: flex !important;
}

.ai-center {
  align-items: center !important;
}
.jc-center {
  justify-content: center !important;
}
.jc-sb {
  justify-content: space-between;
}

// BORDER Radius

.br-5 {
  border-radius: 5px !important;
}
.br-8 {
  border-radius: 8px !important;
}
.br-7 {
  border-radius: 7px !important;
}
.br-10 {
  border-radius: 10px !important;
}
.br-15 {
  border-radius: 15px !important;
}
.br-16 {
  border-radius: 16px !important;
}
.br-20 {
  border-radius: 20px !important;
}
.br-25 {
  border-radius: 25px !important;
}
.br-30 {
  border-radius: 30px !important;
}
.br-32 {
  border-radius: 32px !important;
}
.br-40 {
  border-radius: 40px !important;
}
.wh-border {
  border: 4px solid #d9d9d9;
}

.p-0 {
  padding: 0 !important;
}
.m-0 {
  margin: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.mr-0,
svg.mr-0 {
  margin-right: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}

// 3d carousel
.react-3d-carousel .slider-container {
  width: 100% !important;
  .slider-content {
    width: 85% !important;
  }
}

.carousel-root {
  li.dot {
    height: 9.5px;
    width: 9.5px;
    background: #d9d9d9;
    @include xs {
      height: 4.5px !important;
      width: 4.5px !important;
    }
  }
  li.dot.selected {
    background: var(--selected-color);
    width: 100%;
    max-width: 57px;
    border-radius: 100px;
    height: 9.5px;
    @include xs {
      height: 4.5px !important;
      width: 100% !important;
    }
  }
}

.section-border-bottom {
  border-bottom: 2px solid #2f2e41;
  // width: 1400px !important;
}

.app-section {
  content-visibility: auto;
}

.app-container {
  @include xs {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

.new-container {
  padding-left: 60px !important;
  padding-right: 50px !important;
  max-width: 100%;
  flex-wrap: nowrap !important;

  @include xs {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .app-container {
    @include xs {
      padding: 0px !important;
    }
  }

  .left-container {
    max-width: calc(100vw - 430px);
    width: 100%;
    padding-left: 50px;
    padding-right: 25px;

    @include xs {
      max-width: 100% !important;
      padding: 0px !important;
    }
    @include md {
      max-width: 100% !important;
      padding: 0px !important;
    }
  }

  .right-container {
    max-width: 320px;
    width: 100%;
    padding: 10px;
  }
  .my-container {
    padding-left: 25px;
    padding-right: 25px;
  }
  .full-container {
    padding-left: 50px;
    @include xs {
      max-width: 100% !important;
      padding: 0px !important;
    }
    @include md {
      max-width: 100% !important;
      padding: 0px !important;
    }
    p {
      margin-top: 6px;
      margin-bottom: 6px;
    }
  }
  .new-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.new-container.pr-0 {
  @include md {
    padding-right: 0px !important;
  }
  @include lg {
    padding-right: 0px !important;
  }
  @include xl {
    padding-right: 0px !important;
  }
}
.new-container.pl-25 {
  @include xs {
    padding-left: 0px !important;
  }
  @include sm {
    padding-left: 0px !important;
  }
  @include md {
    padding-left: 185px !important;
  }
  @include lg {
    padding-left: 185px !important;
  }
  @include xl {
    padding-left: 185px !important;
  }
}
.new-container.pl-50 {
  @include xs {
    padding-left: 0px !important;
  }
  @include sm {
    padding-left: 0px !important;
  }
  @include md {
    padding-left: 50px !important;
  }
  @include lg {
    padding-left: 50px !important;
  }
  @include xl {
    padding-left: 50px !important;
  }
}
.right-container-ad {
  position: sticky;
  top: 50px;
  max-width: 300px;
  display: inline-block;
  float: right;
}

.app-container-new {
  padding-left: 150px !important;
  padding-right: 50px !important;
  @include xs {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

.movie-back-btn {
  margin-right: 100px !important;
  background-color: var(--secondary-color);
  border-radius: 50%;
  padding: 3px;
  font-size: 40px !important;
  @include xs {
    margin-right: 20px !important;
    font-size: 24px !important;
    top: 9px !important;
  }
  @include sm {
    margin-right: 40px !important;
  }
  @include md {
    margin-right: 60px !important;
  }
}

.react-multi-carousel-list {
  @include xs {
    .react-multiple-carousel__arrow {
      display: none;
    }
  }
  @include sm {
    .react-multiple-carousel__arrow {
      display: none;
    }
  }
}

.react-multi-carousel-list::-webkit-scrollbar {
  display: none !important;
}

main.fixed-full-view .application-container::-webkit-scrollbar {
  display: none !important;
}

.italic {
  font-style: italic !important;
}

.border-muted {
  border: 1px solid var(--muted-color) !important;
}

.border-selected {
  border: 1px solid var(--selected-color) !important;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none !important;
}

.hide-scrollbar {
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.bg-gradient {
  background: linear-gradient(
    180deg,
    rgba(255, 102, 153, 1) 0%,
    rgba(92, 61, 164, 1) 100%
  );
}

.row {
  display: flex;
  flex-direction: row;
}

.no-absolute {
  .create-wt-bookmark {
    position: relative !important;
  }
}

.media-bookmark {
  .create-wt-bookmark {
    max-width: 35px;
    max-height: 35px;
    margin: 0;
    padding: 0;
    margin-top: -3px !important;
    margin-bottom: 3px !important;
  }
}

.blogs-swiper {
  .swiper-horizontal
    > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
  .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    left: 50%;
    transform: translateX(-60%);
    white-space: nowrap;
    margin: -8px;
  }
  .swiper-pagination-bullet {
    background: #e98888 !important;
    transition: all 0.2s ease-in-out;
    height: 10px;
    width: 10px;

    @include xs {
      height: 7px;
      width: 7px;
    }
  }
  .swiper-pagination-bullet-active,
  .swiper-pagination-bullet:hover {
    background: var(--selected-color) !important;
    width: 15px;
    border-radius: 5px !important;
    opacity: 1 !important;
  }
}

.new-swiper {
  .swiper-horizontal
    > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
  .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    left: 50%;
    transform: translateX(-60%);
    white-space: nowrap;
    margin: -8px;
  }
  .swiper-pagination-bullet {
    background: #e98888 !important;
    transition: all 0.2s ease-in-out;
    height: 10px;
    width: 10px;

    @include xs {
      height: 7px;
      width: 7px;
    }
  }
  .swiper-pagination-bullet-active,
  .swiper-pagination-bullet:hover {
    background: white !important;
    width: 30px;
    border-radius: 5px !important;
    opacity: 1 !important;
  }
}

.mySwiper {
  .swiper-pagination-bullet {
    background: #ddd !important;
    transition: all 0.2s ease-in-out;
    height: 10px;
    width: 10px;
  }
  .swiper-pagination-bullet-active,
  .swiper-pagination-bullet:hover {
    background: var(--selected-color) !important;
    width: 50px;
    border-radius: 5px !important;
    opacity: 1 !important;
  }
}

.watchlist-outline {
  max-width: auto;
  // @include xs {
  //   max-width: 280px !important;
  // }
  @media only screen and (min-width: 601px) {
    max-width: 365px !important;
  }

  @media only screen and (max-width: 600px) {
    max-width: 280px !important;
  }
}

.mobilex {
  overflow-x: hidden;
}

.country-selection {
  fieldset {
    border: none !important;
  }

  .flag {
    border-right: 0px !important;
    @media only screen and (max-width: 420px) {
      display: block;
    }
  }
}
.customButton-wtw {
  border-radius: 32px !important;
  text-transform: lowercase !important;
  color: #ffffff !important;
  width: 100% !important;
  border: 1px solid white !important;
}

.customButton-wtw.clicked {
  border: 1px solid red !important;
}

.component-accordian {
  .acrdn {
    height: 100px;
    overflow-y: hidden;
    box-shadow: inset 0 6px 6px -6px rgba(0, 0, 0, 0.5);
    transition: all 0.4s ease-in-out;
  }
  .acrdn.open {
    height: auto;
  }
  .content-header--gradient {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;
    margin: 0;
    padding: 15px 0;
    background-image: linear-gradient(180deg, rgba(6, 13, 23, 0), #1a1a2e);
  }
}
