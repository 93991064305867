@import "../../media-queries.scss";

.carousel_img {
  width: 100% !important;
  max-width: 100% !important;
  box-sizing: border-box;
  border-radius: 10px;
}
.poster-icon {
  border-radius: 8px;
}
.movie-container {
  position: relative;
}
.top-right {
  padding: 5px;
  width: 32px;
  height: 32px;
  background-color: #16213e;
  border-radius: 16px;
  position: absolute;
  top: 3px;
  right: 3px;
}
.movie-card {
  width: 100%;
  max-width: 881px;
  height: 469px;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position-y: center;
}

.gradient {
  // background: linear-gradient(180deg, rgba(26, 26, 46, 0) 26.51%, #1a1a2e 100%);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, #000000 100%);
  height: 101%;
  width: 100%;
  border-radius: 10px;
  align-items: center;
  border: 1px solid transparent;
}
.timer-container {
  align-items: center;
  justify-content: center;
  // padding: 5px;
  gap: 10px;
  width: 100%;
  max-width: 125px;
  // height: 31px;
  background: #16213e;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.fixed-navbar-mobile {
  position: fixed;
  z-index: 99999;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  .right-search-btn-focused {
    svg.search-icon {
      color: var(--selected-color) !important;
    }
  }
  .right-search-btn-unfocused {
    svg.search-icon {
      color: var(--text-light-grey-color) !important;
    }
  }
}

// progress-container
.progress-container {
  .progress-value {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .bar {
    @include xs {
      max-height: 10px !important;
    }
  }
}

.toplist-container {
  width: 100%;
  background-color: var(--secondary-color);
  border-radius: 8px;
  text-align: center;
}
.no-aspect-ratio{
  max-width: 100%;
  aspect-ratio: auto;
  height: 100%;
}
.toplist-bottom-container {
  width: 100%;
  height: 44px;
  background: #474463;
  border-radius: 0px 0px 8px 8px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.custom-carousel-dots {
  text-align: center;
  .rectangle-dot {
    background: var(--selected-color);
    width: 100%;
    max-width: 60px;
    border-radius: 100px;
    height: 10px;
    display: inline-block;
    border-radius: 100px;
  }
  .circle-dot {
    height: 9.5px;
    width: 10px;
    background: #d9d9d9;
    border-radius: 50%;
    display: inline-block;
    margin-left: 6px;
    margin-right: 6px;
  }
}