.accordian-container {
  border-radius: 16px !important;
  margin-bottom: 24px;
}

.accordian-container:last-child {
  margin-bottom: 0px !important;
}
.accordian-detail-container {
  background-color: #16213e;
}
.accordian-sub-container {
  background-color: #2f2e41 !important;
  border-radius: 16px !important;
  margin-bottom: 16px !important;
  margin-left: 20px !important;
}

.accordian-sub-container:last-child {
  margin-bottom: 0px !important;
}

// .MuiAccordionSummary-root {
//   height: 0px !important;
// }

.privacy-setting-container {
  // padding-left: 55px;
}

.privacy-setting-container:last-child {
  padding-bottom: 0px !important;
}
.prefrence-setting-container {
  border-radius: 16px !important;
  margin-bottom: 24px;
  background-color: white !important;
}
svg.blue-icon {
  color: #16213e !important;
}
.history-point-container {
  border: 1px solid #fff;
  padding: 5px;
  border-radius: 10px;
}
.faq-container {
  border-radius: 16px !important;
  margin-bottom: 24px;
  // background-color: white !important;
}

.settings-page {
  div.MuiAccordionSummary-content.Mui-expanded {
    margin: 12px 0 !important;
  }
  div.MuiAccordionSummary-root {
    min-height: 64px;
  }
}

.settings-page {
  .MuiAccordion-root:before {
    height: 0 !important;
  }
  .MuiAccordion-root {
    box-shadow: none !important;
  }
  .MuiAccordion-root.Mui-expanded {
    margin-bottom: 24px !important;
  }
}

.data-grid-points {
  .MuiDataGrid-main {
    .MuiDataGrid-columnHeadersInner {
      width: 100%;
      > div {
        width: 100%;
      }
      .MuiDataGrid-columnHeader {
        width: 50%;
      }
      .MuiDataGrid-columnHeader:last-child {
        .MuiDataGrid-columnHeaderTitleContainerContent {
          justify-content: flex-end;
          width: 100%;
          text-align: right;
          padding-right: 20px;
        }
      }
    }

    .MuiDataGrid-virtualScrollerRenderZone {
      width: 100%;
      .MuiDataGrid-row {
        width: 100%;
        .MuiDataGrid-cell {
          width: 50%;
        }
        .MuiDataGrid-cell:last-child {
          text-align: right;
          > div {
            display: inline-block;
            width: 100%;
            text-align: right;
            padding-right: 20px;
          }
        }
      }
    }

    svg.MuiSvgIcon-root {
      display: none;
    }
  }
}


