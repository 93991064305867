@import "../../../media-queries.scss";

.search-bar-container {
  width: 100%;
  max-width: 750px;
}

.seacrh-box-input {
  background-color: #fff;
  border: 1px solid #fff;
  color: #000;
  width: 100%;
  padding: 10px;
  font-size: 1.5rem;
  padding-left: 2.5rem;

  @include xs {
    font-size: 1rem;
    padding-left: 2rem;
  }
  @include sm {
    font-size: 1rem;
    padding-left: 2rem;
  }
  @include md {
    font-size: 1.25rem;
    padding-left: 2.25rem;
  }
}

.suggestion-container::-webkit-scrollbar {
  margin-right: 5px;
}

.suggestion-container {
  background-color: #fff;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  max-height: 400px;
  overflow-y: auto;
  z-index: 99;

  .suggestion-list-item {
    color: #000;
    padding: 10px;
    font-size: 1.25rem;

    @include xs {
      font-size: 0.75rem;
    }
    @include sm {
      font-size: 0.75rem;
    }
    @include md {
      font-size: 1rem;
    }
  }
  .suggestion-list-item:hover {
    background-color: #eee;
    cursor: pointer;
    color: var(--selected-color) !important;
    p {
      color: var(--selected-color) !important;
    }
  }
}

.search-icon {
  position: absolute;
  left: 0;
  z-index: 9;
  color: var(--selected-color) !important;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2rem !important;
  margin-left: 10px;

  @include xs {
    font-size: 1.25rem !important;
  }
  @include sm {
    font-size: 1.25rem !important;
  }
  @include md {
    font-size: 1.75rem !important;
  }
}

.mic-icon {
  position: absolute;
  right: 0;
  z-index: 9;
  color: var(--selected-color) !important;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2rem !important;
  margin-right: 10px;
  cursor: pointer;

  @include xs {
    font-size: 1.25rem !important;
  }
  @include sm {
    font-size: 1.25rem !important;
  }
  @include md {
    font-size: 1.75rem !important;
  }
}

#searchIcon, #micIcon {
  color: var(--selected-color) !important;
}

.native-searchbar {
  button.MuiAutocomplete-popupIndicator {
    display: none;
  }
  input {
    font-size: 1.5rem;
  }

  div.MuiInputBase-root {
    background-color: #fff;
    border-radius: 50px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 20px;
    padding-right: 20px !important;
  }

  div.MuiButtonBase-root{
    background-color: var(--selected-color);
  }
  div.MuiAutocomplete-noOptions, div.MuiAutocomplete-loading{
    background-color: #fff;
    color: var(--selected-color);
  }
//   div.MuiAutocomplete-popper{
//     background-color: #fff;
//   }
}
