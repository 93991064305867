@import "../../media-queries.scss";

.movie-banner {
  width: 100%;
  height: 430px;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-y: center;
  background-size: 100% 100%;
  .trailer-button-box {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 50%;
    transform: translateY(-60%);
    .trailer-button {
      background-color: rgba(255, 255, 255, 0.8);
      width: 98px;
      height: 28px;
      border-radius: 100px;
      text-transform: none;
      svg {
        color: var(--secondary-color) !important;
      }
    }
  }
}

.badge-button {
  background-color: #ffffff !important;
  border-radius: 40px !important;
  height: 32px;
  text-transform: none !important;
  color: var(--selected-color) !important;
}

.review-carousel-container {
  .carousel-root {
    li.dot {
      height: 9.5px;
      width: 9.5px;
      background: #d9d9d9;
    }
    li.dot.selected {
      background: var(--selected-color);
      width: 100%;
      max-width: 57px;
      border-radius: 100px;
      height: 9.5px;
    }
  }
}

.emoji-container {
  width: 100%;
  max-width: 100px;
  height: 100px;
  background-color: #1a1a2e;
  border-radius: 4px;
  padding-top: 20px;
  // margin: 10px;
}
.cross-icon-container {
  // background-color: #1daa10 !important;

  svg.MuiSvgIcon-root {
    color: #292929 !important;
  }
}

.trending-menu {
  width: 100%;
  max-width: max-content;
  .my-trending-tabs-button {
    width: 100%;
    background: #fbfbfb;
    border-radius: 8px;
    display: flex;
    justify-content: space-evenly;
    padding-top: 5px;
    padding-bottom: 5px;
    button {
      position: relative;
      display: flex;
      background-color: transparent;
      color: #979797;
      border: none;
      text-transform: capitalize;
      font-weight: 600;
      border: 2px solid #fbfbfb;
      font-size: 14px;
      padding: 10px;
      margin-left: 10px;
      margin-right: 10px;
      cursor: pointer;
      max-width: 78px;
    }
    img {
      margin-right: 8px;
      // margin-top: 5px;
    }
    span {
      width: 2px;
      background-color: #c4c4c4;
    }
    button:hover {
      -ms-transform: none !important; /* IE 9 */
      -webkit-transform: none !important; /* Safari 3-8 */
      transform: none !important;
    }
    button.selected {
      color: var(--selected-color) !important;
      font-weight: 600;
      border: 2px solid #e94560;
      border-radius: 8px;
    }
  }
}
.paragraph-container {
  .css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root {
    height: 120px;
    background-color: #e5e5e5;
    font-weight: 400;
    font-size: 12px;
    // -webkit-align-items: none;
    align-items: flex-start;
  }
}

.movie-detail-banner-container {
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: top;
  width: 100%;
  // max-height: 560px;
  .text-dark-grey {
    color: var(--light-color) !important;
    opacity: 0.7;
  }
}

.blureffect {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}
.movie-detail-banner-custom_bg {
  // background: rgba(0, 0, 0, 0.6);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(6.5px);
  -webkit-backdrop-filter: blur(8.5px);
}
.movie-detail-trailer {
  svg {
    color: var(--light-color) !important;
  }
}

@function set-notification-text-color($color) {
  @if (lightness($color) > 50) {
    @return #000000; // Lighter backgorund, return dark color
  } @else {
    @return #ffffff; // Darker background, return light color
  }
}

.build-review-container {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.999) 19.35%,
    rgba(22, 33, 62, 0.999) 44.43%,
    rgba(233, 69, 96, 0.999) 90.41%,
    rgba(23, 22, 62, 0.999) 100%
  );
}

.custom-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  .switch-button {
    border-radius: 30px;
    overflow: hidden;
    width: 180px;
    text-align: center;
    font-size: 18px;
    color: var(--light-color);
    position: relative;
    padding-right: 90px;
    position: relative;
    border: 1px solid #ffffff;
    span {
      top: -3px;
    }

    &:before {
      content: "on mobile";
      top: -4px;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 90px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 3;
      pointer-events: none;
    }

    &-checkbox {
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 2;

      &:checked + .switch-button-label:before {
        transform: translateX(90px);
        transition: transform 300ms linear;
      }

      & + .switch-button-label {
        position: relative;
        padding: 5px 0;
        display: block;
        user-select: none;
        pointer-events: none;

        &:before {
          content: "";
          background: var(--selected-color);
          height: 100%;
          width: 100%;
          position: absolute;
          left: 0;
          top: 0;
          border-radius: 30px;
          transform: translateX(0);
          transition: transform 300ms;
        }

        .switch-button-label-span {
          position: relative;
        }
      }
    }
  }

  .switch-button-2 {
    border-radius: 30px;
    overflow: hidden;
    width: 180px;
    text-align: center;
    font-size: 18px;
    color: var(--light-color);
    position: relative;
    padding-right: 90px;
    position: relative;
    border: 1px solid #ffffff;
    span {
      top: -3px;
    }

    &:before {
      content: "mobile";
      top: -4px;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 90px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 3;
      pointer-events: none;
    }

    &-checkbox {
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 2;

      &:checked + .switch-button-label:before {
        transform: translateX(90px);
        transition: transform 300ms linear;
      }

      & + .switch-button-label {
        position: relative;
        padding: 5px 0;
        display: block;
        user-select: none;
        pointer-events: none;

        &:before {
          content: "";
          background: var(--selected-color);
          height: 100%;
          width: 100%;
          position: absolute;
          left: 0;
          top: 0;
          border-radius: 30px;
          transform: translateX(0);
          transition: transform 300ms;
        }

        .switch-button-label-span {
          position: relative;
        }
      }
    }
  }
}

.qr-scanner {
  padding: 10px;
  section > div {
    padding: 0px !important;
    border-radius: 7px;
    overflow: hidden;
  }
  // video {
  //   border-radius: 7px;
  //   position: relative !important;
  //   width: 400px !important;
  //   height: 400px !important;
  //   object-fit: cover;
  //   max-width: auto;
  //   object-position: 50% 50%;
  // }
  .relative {
    margin-top: 10px;

    .camera-switch {
      position: absolute;
      cursor: pointer;
      top: 10px;
      right: 10px;
      font-size: 32px;
      z-index: 10;
    }

    .square-overlay {
      // background-color: rgba(0, 0, 0, 0.7);
      position: absolute;
      border-radius: 7px;
      top: 0;
      left: 0;
      // right: 0;
      // left: 0;
      // transform: translate(-50%, -50%);
      width: 200px;
      min-width: 100%;
      min-height: 100%;
      height: 200px;
      border-left: 25px;
      border-right: 25px;
      border-top: 25px;
      border-bottom: 150px;
      border-color: rgba(0, 0, 0, 0.5);
      border-style: solid;
      // border: 1px dashed var(--selected-color);
      // border-right-width: 25%;
      // border-left-width: 25%;
      // border-color: red;
      // border-style: solid;
      // box-shadow: 0.5rem 0.5rem black, -0.5rem -0.5rem #ccc;

      .inner-1 {
        width: 20px;
        background-color: var(--selected-color);
        height: 5px;
        border-radius: 0 !important ;
        opacity: 1 !important;
      }

      .inner {
        background-color: var(--selected-color);
        height: 20px;
        width: 5px;
        border-radius: 0 !important ;
        opacity: 1 !important;
      }

      .left {
        position: absolute;
        left: 0;
        top: 0;
      }

      .right {
        position: absolute;
        right: 0;
        top: 0;
      }

      .right-bottom,
      .bottom-right {
        position: absolute;
        right: 0;
        bottom: 0;
      }

      .left-bottom,
      .bottom-left {
        position: absolute;
        left: 0;
        bottom: 0;
      }

      .relative {
        height: 100%;
        width: 100%;
        margin-top: 0px !important;

        p {
          position: absolute;
          bottom: -20px;
          margin: 0;
          padding: 0;
          width: 100%;
          text-align: center;
          text-shadow: 1px 1px 2px #000;
        }
      }
    }
  }

  // new qr code scanner

  #reader {
    border: none !important;
    margin-top: 20px;
    overflow: hidden;
  }

  #reader__scan_region {
    overflow: hidden;
  }

  button {
    border-radius: 40px !important;
    height: 44px !important;
    max-width: fit-content;
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    border: 0;
    text-transform: none !important;
    font-weight: 600 !important;
    cursor: pointer;
    background-color: var(--selected-color);
    color: #fff;
    transition: all 0.5ms;
  }
  button:hover {
    background-color: #fff;
    color: var(--selected-color);
  }

  #html5-qrcode-button-camera-stop,
  #html5-qrcode-select-camera,
  #reader__header_message,
  span[style="margin-right: 10px;"],
  img[alt="Info icon"],
  button:disabled {
    display: none !important;
  }
}

.scan-btns {
  border: none;
  background-color: var(--gun-powder-color);
  width: 100%;
  display: flex !important;
  margin-top: 20px;
  align-items: center;
  padding-left: 28px;
  padding-right: 28px;
  height: 56px;
  border-radius: 28px;

  @include xs {
    padding-left: 20px;
    padding-right: 20px;
    height: 40px;
    border-radius: 20px;
  }

  span {
    margin-left: 20px;
    margin-right: 20px;
  }

  svg:last-child {
    position: absolute;
    right: 28px;
    top: 50%;
    transform: translateY(-50%);
    @include xs {
      right: 20px;
    }
  }
}

#scan-close {
  background-color: transparent;
  border: none;
}
