@import "../../media-queries.scss";

.partner-search-box-container {
  width: 100%;
  background-color: var(--background-color);
  margin-bottom: 10px;
  @include xs {
    top: 36px;
  }
  @include sm {
    top: 54px;
  }
  @include md {
    top: 60px;
  }

  .search-container {
    margin-top: 30px;

    @include xs {
      margin-top: 5px;
    }
    @include sm {
      margin-top: 10px;
    }
    @include md {
      margin-top: 20px;
    }
  }

  .horizontal-line {
    background-color: var(--selected-color);
    width: 100%;
    height: 1px;
    max-width: 700px;
    margin-top: 20px;
  }

  .trending-list {
    width: 100%;
    max-width: 700px;
  }
}

.search-results-container {
  width: 100%;
  max-width: 1550px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;

  .tile-container {
    text-align: center;
    // margin-left: 5px;
    // margin-right: 5px;
  }

  .pagination-container {
    margin-top: 20px;
    li > button:not(.Mui-selected),
    li > div {
      color: #ddd;
      border-color: #ddd;
    }
  }
}

#demo-multiple-checkbox,
#demo-select-small,
#autocomplete {
  color: var(--selected-color) !important;
  div,
  span {
    color: var(--selected-color) !important;
  }
}

.MuiAutocomplete-popperDisablePortal {
  color: #ddd !important;
}
