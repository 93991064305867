.activity-avatar {
  width: 100%;
  max-width: 24px;
  min-width: 24px;
  max-height: 24px;
  border-radius: 50%;
  margin-left: -10px !important;
}

.shareListPoster {
  margin-left: -10px !important;
  &:first-child {
    margin-left: 0px !important;
  }
}
