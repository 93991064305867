@import "../../media-queries.scss";

.search-box-container {
  // height: 0px;
  position: sticky;
  top: 0px;
  z-index: 1001;
  border-radius: 0px;
  // padding: 15px;
  padding-top: 25px;
  padding-left: 20px;
  background-color: var(--background-color);
  margin-bottom: 10px;
  @include xs {
    top: 36px;
  }
  @include sm {
    top: 54px;
  }
  @include md {
    top: 60px;
  }
  .search-result-show {
    position: absolute;
    z-index: 1002;
    width: 100%;
    left: 0;
    right: 0;
  }
}

.search-filter-drawer-content {
  div.MuiAccordion-rounded.Mui-expanded {
    margin: 0 !important;
    .MuiAccordionSummary-root {
      min-height: 48px !important;
      .MuiAccordionSummary-content {
        margin: 12px 0 !important;
      }
    }
  }
}

.filter-count-show{
  position: absolute;
  top: -3px;
  right: -3px;
  font-size: 12px;
  width: 15px;
  height: 15px;
  text-align: center;
  line-height: 13px;
  border-radius: 50%;
  z-index: 1;
}