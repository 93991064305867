@import "../../media-queries.scss";

.movie-detail-card {
  width: 100%;
  //   max-width: 1166px;
  height: 430px;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position-y: center;
}

.gradient {
  // background: linear-gradient(180deg, rgba(26, 26, 46, 0) 26.51%, #1a1a2e 100%);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, #000000 100%);
  height: 101%;
  width: 100%;
  border-radius: 10px;
  align-items: center;
  border: 1px solid transparent;
}
.bookmark-icon {
  padding: 5px;
  width: 32px;
  height: 32px;
  background-color: #16213e;
  border-radius: 16px;
}
.dot {
  height: 8px;
  width: 8px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
 
 
  margin-right: 5px;
  margin-left: 5px;
}
.ua-box {
  width: 31px;
  height: 18px;
  border: 0.5px solid #d9d9d9;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
// .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
//   border-color: red !important;
//   background-color: #16213e !important;
//   border-radius: 50% !important;
//   width: 102px !important;
//   height: 50px !important;
// }
.platform-container {
  width: 320px;
  height: 48px;
  background: #f2f2f2;
  border-radius: 44px;
  align-items: center;
  justify-content: center;
}
.social-buzz {
  width: 378px;
  height: 48px;
  background: #f39200;
  border-radius: 0px 0px 8px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
}
.language-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  // cursor: pointer;
}
.poster-icon {
  width: 100%;
  border-radius: 8px;
  max-width: 270px;
  height: 360px;
}
.movie-container {
  position: relative;
}
.top-right {
  padding: 5px;
  width: 32px;
  height: 32px;
  background-color: #16213e;
  border-radius: 16px;
  position: absolute;
  top: 6px;
  right: 9px;
}
.platform-poster-icon {
  width: 100%;
  border-radius: 8px;
  max-width: 147px;
  height: 210px;
}
.platform-outer-ring {
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  img {
    border: 4px solid var(--selected-color);
  }
}
.add-platform-container {
  border-radius: 50%;
  width: 100%;
  width: 80px;
  height: 80px;
  padding: 5px;
  color: var(--selected-color) !important;
  background-color: var(--light-color);
  justify-content: center;
  align-items: center;
  display: flex;
  transition: all 0.2s ease-in-out;
  svg.MuiSvgIcon-root {
    color: var(--selected-color) !important;
    transition: all 0.2s ease-in-out;
  }
}

.add-platform-container:hover {
  background-color: var(--selected-color);
  color: var(--light-color);
  svg {
    color: var(--light-color) !important;
  }
}

.ott-poster {
  width: 100%;
  border-radius: 4px 4px 0px 0px;
  max-width: 149px;
  height: 219px;
}
.in-cinema-containetr {
  width: 100%;
  background: #000000;
  border-radius: 0px 0px 4px 4px;
  position: absolute;
  bottom: 0;
}
.ott-dot {
  width: 4px;
  height: 4px;
  background: #d9d9d9;
  border-radius: 100px;
  margin-right: 4px;
  margin-left: 4px;
}
.ott-progress-bar {
  width: 36px;
  height: 14px;
  border-radius: 32px;
  background-color: green;
  align-items: center;
  justify-content: center;
  display: flex;
}
// .ott-avatar {
//   margin-left: -10px !important;
// }
.ott-avatar {
  width: 36px;
  height: 36px;
  margin-left: -20px !important;
  &:first-child {
    margin-left: 0px !important;
  }
  @include xs {
    width: 24px !important;
    max-height: 24px !important;
    min-width: 24px !important;
    margin-left: -15px !important;
  }
}
.whom-watching-container {
  width: 100%;
  max-width: 362px;
  height: 219px;
  bottom: 10px;
  position: relative;
  margin-right: 20px;
}
.whom-watching-poster {
  position: absolute;
  bottom: 0px;
}
.circle-lang-container {
  width: 100%;
  max-width: 60px;
  min-width: 40px;
  max-height: 60px;
  min-height: 40px;
  border-radius: 50%;
  padding-left: 5px;
  padding-right: 5px;
  background-color: var(--secondary-color);
  align-items: center;
  justify-content: center;
  display: flex;
  transition-property: width;
  transition-timing-function: ease;
  transition-delay: 2s;
  transition: all 0.3s ease-in-out;
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.circle-lang-container.lang-selected{
  background-color: var(--selected-color);
  border: none !important;
}

.circle-lang-container.lang-selected,
.circle-lang-container:hover {
  // background-color: var(--selected-color);
  min-width: 150px;
  max-width: auto;
  border-radius: 32px;
  width: 100%;
  color: var(--light-color);
  padding-left: 5px;
  padding-right: 5px;
  p {
    color: var(--light-color);
  }
  border:1px solid white;
}

.add-language-container {
  border-radius: 50%;
  max-width: 60px;
  min-width: 40px;
  max-height: 60px;
  min-height: 40px;
  padding: 5px;
  background-color: var(--light-color);
  color: var(--selected-color);
  justify-content: center;
  align-items: center;
  display: flex;
  transition: all 0.2s ease-in-out;
  svg.MuiSvgIcon-root {
    color: var(--selected-color) !important;
    transition: all 0.2s ease-in-out;
  }
}

.add-language-container:hover {
  background-color: var(--selected-color);
  color: var(--light-color);
  svg {
    color: var(--light-color) !important;
  }
}

.trending-carousel-container {
  .carousel-root {
    li.dot {
      height: 9.5px;
      width: 9.5px;
      background: #d9d9d9;
    }
    li.dot.selected {
      background: var(--selected-color);
      width: 100%;
      max-width: 57px;
      border-radius: 100px;
      height: 9.5px;
    }
  }
  .carousel.carousel-slider {
    padding-bottom: 40px;
  }
}

.tag-container {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1111;
  // max-width: 340px;
  max-width: 160px;
  width: 100%;
}

.tag-container .relative::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  // top: -50%;
  // left: -50%;
  z-index: -1;
  background: url("https://d1ngx337m7c4sq.cloudfront.net/web-prod/assets/icons/BookmarkBG.svg") 0 0 no-repeat;
  transform: rotate(180deg);
}

.whom-watching {
  background-color: var(--light-color);
  width: 100%;
  height: 100%;
  // max-width: 520px;
  max-height: 200px;
  border-radius: 16px;
  cursor: pointer;
  .title-container {
    position: absolute;
    right: 10%;
    top: 50%;
    transform: translateY(-50%);
  }
}
