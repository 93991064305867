.language-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  // cursor: pointer;
  overflow-x: hidden;
  overflow-y: auto;
}

// ::-webkit-scrollbar {
//   width: 8px;
//   // height: 100px !important;
//   // background: #737176;
//   border-radius: 8px;
// }
.rounded-container {
  width: 100px;
  height: 100px;
  // border-radius: 50px;
  border-radius: 50%;
  background-color: #1a1a2e;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
}

.rounded-container-box {
  background-color: var(--selected-color);
}

.overlay-selected {
  background-color: rgba(0, 0, 0, 0.8);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: absolute;
  top: 0px;
  right: 0;
  left: 0px;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.overlay-selected-watchlist {
  background-color: rgba(0, 0, 0, 0.8);
  width: 72px;
  height: 58px;
  border-radius: 8px;
  position: absolute;
  top: 0;
  right: 0;
  left: 5px;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.rounded-container-img {
  width: 100px;
  height: 123px;
  margin-top: 21px;
}

.selected-overlay-rect {
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  max-height: 210px;
  border-radius: 8px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

// .rounded-icon {
//   width: 60px;
//   height: 60px;
// }

.poster-container {
  width: 102px;
  height: 155px;
}
.poster-icon {
  border-radius: 8px;
}
.handpick-poster-icon {
  width: 100%;
  border-radius: 8px;
  max-width: 105px;
  height: 150px;
}
.movie-container {
  position: relative;
  > svg {
    z-index: 999;
  }
}
.top-right {
  padding: 5px;
  width: 32px;
  height: 32px;
  background-color: #16213e;
  border-radius: 16px;
  position: absolute;
  top: 3px;
  right: 3px;
}
.existing-watchlist-poster {
  width: "72px" !important;
  height: "58px" !important;
  border-radius: 8px;
}

.handpick-movie-search-panel {
  position: absolute;
  width: 100%;
  max-width: 300px;
  max-height: 358px;
  top: 50px;
  z-index: 99999;
  padding: 11px;
  overflow-y: auto;
  background: #fff;
  border-radius: 20px;
}

.left-search-btn-focused {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
  background-color: var(--selected-color) !important;
}
