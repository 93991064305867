.sub-headings{
    font-weight:400px ;
    font-size:20px ;
    color: white !important ;
    display: block;
    opacity: 0.8;
}

.headings{
    font-weight:500px ;
    font-size:24px ;
    color: white !important;
    display: block;
    line-height: 34px;
}

.email-footer{
    .MuiOutlinedInput-root{
    height:'40px'
}}


