@import "../../media-queries.scss";

.language-tv-container {
  width: 100%;
  max-width: 58px !important;
  background: #16213e;
  opacity: 0.8;
  border-radius: 10px;
  position: absolute;
  bottom: 15px;
  right: 17px;
  padding-bottom: 2px;
  justify-content: center;
  align-items: center;
}
.wt-bookmark {
  position: absolute;
  padding: 3px;
  right: 14px;
  top: 10px;
  width: 32px;
  height: 32px;
  background-color: var(--secondary-color);
  border-radius: 50%;
}
.create-wt-bookmark {
  position: absolute;
  padding: 3px;
  right: 5px;
  top: 5px;
  width: 35px;
  height: 35px;
  // background-color: var(--secondary-color);
  border-radius: 50%;
  cursor: pointer;
}
.popular-container {
  padding: 4px 12px;
  background: #ff9933;
  border-radius: 96px;
  width: 100%;
  height: auto;
  text-align: center;
  color: #000000;
}
.gt-popular-container {
  padding: 4px 12px;
  max-width: 131px;
  height: 20px;
  background: #33cc99;
  border-radius: 96px;
  width: 100%;
  height: auto;
  text-align: center;
  color: #000000;
}
.watchlist-title-search-panel {
  position: absolute;
  width: 100%;
  max-width: 1208px;
  max-height: calc(100vh - 200px);
  top: 50px;
  z-index: 99999;
  padding: 11px;
  overflow-y: auto;
  border-radius: 20px;
}
.watchlist-banner-btns {
  position: absolute;
  width: 100%;
  top: 32px;
  right: 0;
  left: 0;
  width: 45px;
  height: 45px;
  svg {
    background-color: var(--secondary-color);
    border-radius: 50%;
    font-size: 32px;
    padding: 5px;
  }
}
.watchlist-container-heading {
  background-color: var(--gun-powder-color);
  border-radius: 32px 32px 0px 0px;
  margin-top: -35px;
  z-index: 99;
  position: relative;
}
.plus-selected {
  background-color: var(--secondary-color);
  // color: var(--selected-color) !important;
  width: 40px;
  height: 40px;
  background: #16213e;
  border: 2px solid #16213e;
  border-radius: 20px;
  .svg.MuiSvgIcon-root {
    color: red !important;
  }
}
.watchlist-avatar {
  width: 100%;
  max-width: 40px;
  height: 40px !important;
  margin-left: -25px !important;
  &:last-child {
    // border: 0;
  }
}
.rounded-circle {
  background-color: var(--secondary-color);
  width: 40px;
  height: 40px;
  border: 2px solid #16213e;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-watchlist {
  .custom-accordian {
    background-color: transparent !important;
    div.header {
      min-height: auto;
      .MuiAccordionSummary-content {
        margin: 12px 0 !important;
      }
    }
  }

  div.MuiButtonBase-root:before {
    opacity: 0 !important;
  }
}

.wathlist-title-search {
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.8) !important;
  }
}

div.watchlist-title-selected {
  .selected-watchlist-icon {
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    border-radius: 8px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
.floating-btn-box {
  position: sticky;
  bottom: 0 !important;
}

.sticky-top {
  position: sticky;
  @include xs {
    top: 39px !important;
  }
  @include sm {
    top: 53px !important;
  }
  @include md {
    top: 0px !important;
  }
  @include lg {
    top: 0px !important;
  }
  @include xl {
    top: 0px !important;
  }
}

.sticky-bottom {
  position: relative;
  z-index: 1;
  border-radius: 32px 32px 0px 0px;
  background-color: var(--secondary-color);
  > div.sticky-top {
    z-index: 5;
  }
}

.text-selected {
  svg.MuiSvgIcon-root {
    color: var(--selected-color) !important;
  }
}

.search-result-show {
  position: absolute;
  z-index: 1002;
  width: 100%;
  left: 0;
  right: 0;
}

.unwatched-title-ott {
  display: inline-block;
  margin-top: 10px;
  .unwatched-title-inner {
    background-color: var(--gun-powder-color);
    display: flex;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 20px;
    padding-right: 5px;
    border-radius: 32px;
    img {
      margin-left: 10px;
    }
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  padding-top: 20px;
  text-align: center;
}
