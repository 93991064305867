@import "../../media-queries.scss";

#frenzi-clips-page {
  max-width: 100vw;
  max-height: 100vh;
  position: relative;
  @include xs {
    padding-top: 0px;
  }
  @include sm {
    padding-top: 55px;
  }
  .clips-arow {
    height: 70px;
    width: 70px;
    background-color: var(--selected-color);
    position: absolute;
    right: 40px;
    bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    z-index: 99;
    transition: all 0.3s ease-in-out;
    svg.MuiSvgIcon-root {
      font-size: 32px;
    }
  }
  .clips-arow:hover {
    background-color: var(--light-color);
    svg.MuiSvgIcon-root {
      color: var(--selected-color) !important;
    }
  }

  .mySwiper {
    width: 100%;
    height: 100%;
    @include xs {
      // height: calc(100vh - 97px);
      // height: calc((var(--vh, 1vh) * 100) - 53px);
      // max-height: -webkit-fill-available;
      // height: calc(100% - 50px);
      // height: 100%;
      // padding-bottom: 97px;
      height: calc((var(--vh, 1vh) * 100) - 105px);
    }
    height: 100vh;
  }
  .clips-item-container {
    height: 100%;
    width: 100%;
    // background-color: aqua;
    padding-top: 12px;
    padding-bottom: 12px;
    @include xs {
      padding: 0 !important;
    }

    .frenzi-clips-player {
      max-width: 100%;
      object-fit: cover;

      video {
        height: 100% !important;
        border-radius: 10px;
        width: 100% !important;
        object-fit: cover;
        @include xs {
          border-radius: 0px;
        }
      }
    }

    .carousel-root {
      height: 100%;
      .carousel-slider {
        height: 100%;
        .slider-wrapper {
          height: 100% !important;
        }
      }
    }

    .clips-action-icon {
      position: absolute;
      z-index: 9;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      // opacity: 1;
    }

    .clip-progress-bar {
      background-color: rgba(255, 255, 255, 0.3);
      height: 3px;
      border-radius: 1.5px;
      position: absolute;
      bottom: 0;
      width: 100%;
      div.bar {
        background-color: var(--selected-color);
        height: 100%;
        // transition: all 0.2s ease-in-out;
      }
    }

    .media-container {
      overflow: hidden;
      border-radius: 10px;
      @include xs {
        border-radius: 0px;
      }
    }

    // .media-container:hover {
    //   .clips-action-icon,
    //   .mute-unmute-icon {
    //     opacity: 1;
    //   }
    // }

    .mute-unmute-icon {
      position: absolute;
      right: 10px;
      top: 10px;
      padding: 5px;
      z-index: 10;
      border-radius: 50%;
      // opacity: 0;
    }

    .camera-rec-icon {
      position: absolute;
      right: 40px;
      top: 9px;
      padding: 5px;
      z-index: 10;
    }

    .media-details {
      padding: 10px;
      position: absolute;
      bottom: 5px;
      // width: 100%;
      z-index: 99;
    }

    .owner-details {
      padding: 10px;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 99;
    }

    p.shadow,
    span.shadow {
      text-shadow: 1px 1px 2px #000;
    }
  }

  .swiper-pagination-bullet-active {
    background: var(--selected-color) !important;
  }
  .swiper-pagination-bullet {
    background: var(--light-color);
  }
}

.clip-comment-input-box {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: var(--primary-color);
  position: sticky;
  bottom: 0px;

  .clip-comment-input {
    border: 1px solid #fff;
    border-radius: 7px;
    background-color: transparent;
    width: 100%;
    padding: 5px;
    font-size: 18px;
  }

  .cross-icon {
    position: absolute;
    right: 25px;
    top: 50%;
    height: 24px;
    transform: translateY(-50%);
    z-index: 9;
  }

  svg.send-icon {
    position: absolute;
    right: 0px;
    font-size: 30px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
  }
}

.react-player__shadow {
  display: none !important;
}

.autocomplete-box {
  textarea {
    background-color: transparent;
    width: 100%;
    height: calc(100% - 35px);
    max-width: 100%;
    max-height: 100%;
    resize: none;
  }
  .react-autocomplete-input {
    background-color: var(--primary-color);
    height: calc(100% - 10px);
    overflow-y: auto;
  }
  .react-autocomplete-input > li.active {
    background-color: var(--selected-color) !important;
  }
}

.clips-modal {
  .search-box-container {
    position: static !important;
  }
}

.clip-thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
